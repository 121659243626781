import {
  createRouter,
  createWebHashHistory
} from 'vue-router'

const routes = [{
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  // {
  //   path: '/test',
  //   name: 'Test',
  //   component: () => import('../views/Test.vue')
  // },
  {
    path: '/article/:type',
    name: 'Article',
    component: () => import('../views/Article.vue')
  },
  {
    path: '/signIn',
    name: 'SignIn',
    component: () => import('../views/SignIn.vue')
  },
  {
    path: '/emporiumDetails/:id',
    name: 'EmporiumDetails',
    component: () => import('../views/EmporiumDetails.vue')
  },
  
  {
    path: '/productDetails/:id',
    name: 'ProductDetails',
    component: () => import('../views/ProductDetails.vue')
  },
  {
    path: '/emporiumList/:id',
    name: 'EmporiumList',
    component: () => import('../views/EmporiumList.vue')
  },
  {
    path: '/productList/:id',
    name: 'ProductList',
    component: () => import('../views/ProductList.vue')
  },
  {
    path: '/insuranceApplyDetail/:id',
    name: 'InsuranceApplyDetail',
    component: () => import('../views/InsuranceApplyDetail.vue')
  },
  {
    path: '/ticketBuyIn/:id',
    name: 'TicketBuyIn',
    component: () => import('../views/TicketBuyIn.vue')
  },
  {
    path: '/emporiumOrderList/:status',
    name: 'EmporiumOrderList',
    component: () => import('../views/EmporiumOrderList.vue')
  },
  {
    path: '/markOrderList/:status',
    name: 'MarkOrderList',
    component: () => import('../views/MarkOrderList.vue')
  },
  {
    path: '/insuranceApplyList/:status',
    name: 'InsuranceApplyList',
    component: () => import('../views/InsuranceApplyList.vue')
  },
  {
    path: '/insuranceOrderDetailInfo/:id',
    name: 'InsuranceOrderDetailInfo',
    component: () => import('../views/InsuranceOrderDetailInfo.vue')
  },
  {
    path: '/public',
    name: 'Public',
    component: () => import('../views/Public.vue')
  },
  {
    path: '/applyForm/:id',
    name: 'ApplyForm',
    component: () => import('../views/ApplyForm.vue')
  },
  {
    path: '/insuranceList/:statu',
    name: 'InsuranceList',
    component: () => import('../views/InsuranceList.vue')
  },
  {
    path: '/medSecd/:type',
    name: 'MedSecd',
    component: () => import('../views/MedSecd.vue')
  },
  {
    path: '/stuMedInvoice',
    name: 'StuMedInvoice',
    component: () => import('../views/StuMedInvoice.vue')
  },
  {
    path: '/stuInvoice',
    name: 'StuInvoice',
    component: () => import('../views/StuInvoice.vue')
  },
  {
    path: '/catalog/:type',
    name: 'Catalog',
    component: () => import('../views/Catalog.vue')
  },
  {
    path: '/articleDetail/:id',
    name: 'ArticleDetail',
    component: () => import('../views/ArticleDetail.vue')

  },
  {
    path: '/catalogDetail/:id',
    name: 'CatalogDetail',
    component: () => import('../views/CatalogDetail.vue')

  },
  {
    path: '/newsList/:sort',
    name: 'NewsList',
    component: () => import('../views/NewsList.vue')
  },
  {
    path: '/newsDetail/:ids',
    name: 'NewsDetail',
    component: () => import('../views/NewsDetail.vue')
  },
  {
    path: '/medicare',
    name: 'Medicare',
    component: () => import('../views/Medicare.vue')
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () => import('../views/Shop.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import('../views/Cart.vue')
  },
  {
    path: '/productOrderin',
    name: 'ProductOrderin',
    component: () => import('../views/ProductOrderin.vue')
  },
  {
    path: '/productSpecialCheckin',
    name: 'productSpecialOrderin',
    component: () => import('../views/ProductSpecialOrderin.vue')
  },
  {
    path: '/emporiumOrderin',
    name: 'EmporiumOrderin',
    component: () => import('../views/EmporiumOrderin.vue')
  },
  {
    path: '/emporiumOrderinCart',
    name: 'EmporiumOrderinCart',
    component: () => import('../views/EmporiumOrderinCart.vue')
  },
  {
    path: '/orderSuccess',
    name: 'OrderSuccess',
    component: () => import('../views/OrderSuccess.vue')
  },
  {
    path: '/orderDetails/:id/:type',
    name: 'OrderDetails',
    component: () => import('../views/OrderDetails.vue')
  },
  {
    path: '/active',
    name: 'Active',
    component: () => import('../views/Active.vue')
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/User.vue')
  },
  {
    path: '/search/:keyword',
    name: 'Search',
    component: () => import('../views/Search.vue')
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router